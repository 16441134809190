<template>
  <span>
    <b-row>
      <b-col>
        <h2>Details</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <DxDataGrid
          key-expr="id"
          :data-source="dataSource"
          :focused-row-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          @init-new-row="handleEvent('init-new-row', $event)"
          @option-changed="handleEvent('option-changed', $event)"
          @row-removed="handleEvent('row-removed', $event)"
        >
          <DxEditing
            :allow-updating="!isDisabled"
            :allow-deleting="!isDisabled"
            :allow-adding="!isDisabled"
            :use-icons="!isDisabled"
            mode="row"
          />

          <DxColumn v-if="!isDisabled" type="buttons">
            <!-- <DxButton v-if="!isDisabled" icon="image" :onClick="previewItem" /> -->
            <DxButton icon="edit" :onClick="editItem" />

            <DxButton name="delete" />
          </DxColumn>

          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxSorting mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="false" />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel location="before" :visible="true" />

          <DxColumn data-field="product_id" caption="Product">
            <DxLookup
              :data-source="productItems"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>

          <DxColumn
            :visible="false"
            data-field="serial_number"
            caption="Serial No."
          />

          <DxColumn
            v-for="(item, index) in dataColumns"
            :key="index"
            :data-field="item.field"
            :caption="item.caption"
            :cell-template="item.cellTemplate"
          />

          <template #cellPriceTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>
          <template #cellGrossAmountTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>
          <template #cellDiscountPercentTemplate="{ data }">
            {{ `${data.value}%` }}
          </template>
          <template #cellDiscountAmountTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>
          <template #cellTotalTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>

          <DxToolbar>
            <DxItem location="before" name="columnChooserButton" />
            <DxItem location="before" name="searchPanel" />
            <DxItem name="groupPanel" />

            <DxItem v-if="!isDisabled" template="addButtonTemplate" />
          </DxToolbar>

          <DxSummary>
            <DxGroupItem
              column="id"
              summary-type="count"
              display-format="{0} orders"
            />
            <DxGroupItem
              :show-in-group-footer="false"
              :align-by-column="true"
              column="post_amount"
              summary-type="sum"
              value-format="currency"
            />
            <DxGroupItem
              :show-in-group-footer="true"
              column="post_amount"
              summary-type="sum"
              value-format="currency"
              display-format="Total: {0}"
            />
          </DxSummary>

          <DxSortByGroupSummaryInfo summary-item="count" />

          <template #addButtonTemplate>
            <div>
              <b-button
                variant="primary"
                class="text-capitalize"
                @click="addModal = true"
              >
                Add
              </b-button>
            </div>
          </template>
        </DxDataGrid>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right">
        <div class="">
          Total Amount: <strong>{{ moneyFormat(computedSummaryTotal) }}</strong>
        </div>
        <div class="">
          GST Amount: <strong>{{ moneyFormat(computedSummaryGST) }}</strong>
        </div>
        <div class="">
          Final Amount: <strong>{{ moneyFormat(computedSummaryFinal) }}</strong>
        </div>
      </b-col>
    </b-row>
    <br />

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Product"
      @hide="closeModal()"
    >
      <b-row>
        <b-col class="text-right">
          <ProductSearch @onSelect="(v) => selectProduct(v)" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Product Name" label-for="v-name">
            <b-form-input disabled v-model="formdata_detail.name" id="v-name" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="UOM" label-for="v-uom">
            <b-form-input disabled v-model="formdata_detail.uom" id="v-uom" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="SKU" label-for="v-sku">
            <b-form-input disabled v-model="formdata_detail.sku" id="v-sku" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Quantity" label-for="v-quantity">
            <cleave
              id="v-quantity"
              name="quantity"
              v-model="watchQty"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Price" label-for="v-price">
            <cleave
              disabled
              id="v-price"
              name="price"
              v-model="watchPrice"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Gross Amount" label-for="v-gross_amount">
            <cleave
              disabled
              id="v-gross_amount"
              name="gross_amount"
              v-model="watchGrossAmount"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Discount Percent" label-for="v-discount_percent">
            <cleave
              id="v-discount_percent"
              name="discount_percent"
              v-model="watchDiscountPercent"
              class="form-control text-right"
              :raw="false"
              :options="options.discountPercentCleave"
              max="100"
              min="0"
              step="1"
              maxlength="3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Discount Amount" label-for="v-discount_amount">
            <cleave
              id="v-discount_amount"
              name="discount_amount"
              v-model="watchDiscountAmount"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Total" label-for="v-post_amount">
            <cleave
              disabled
              id="v-post_amount"
              name="post_amount"
              v-model="watchTotal"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import productsApi from '@api/products';
import { moneyFormat as mf } from '@/global-functions';
import { queryParameters } from '@/schema';

import Cleave from 'vue-cleave-component';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us';

export default {
  name: 'QuotationDetailsComponent',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Cleave,
  },
  watch: {
    dataSource(v) {
      //
    },
    watchQty(v) {
      const fd = this.formdata_detail;

      if (this.dataIndex > -1 && +v !== fd.quantity) {
        this.watchDiscountPercent = '0.00';
        this.watchDiscountAmount = '0.00';
      }

      const value = v !== '' && typeof v == 'string' ? v.replace(/,/g, '') : 0;
      fd.quantity = +value;
      fd.gross_amount = fd.quantity * fd.price;
      this.watchGrossAmount = fd.quantity * fd.price;

      const dp = +fd.discount_percent;
      const da = +fd.discount_amount;

      if (!+dp) {
        fd.post_amount = fd.gross_amount;
        this.watchTotal = fd.post_amount;
      }

      if (v === '') {
        this.watchQty = 0;
      }
    },
    watchDiscountPercent(v) {
      const fd = this.formdata_detail;
      let value = v !== '' && typeof v == 'string' ? v.replace(/,/g, '') : 0;

      if (value < 0 || value > 100) {
        this.toastConfig('Discount exceed 100%', 'error');
        this.watchDiscountPercent = '0.00';
        return;
      }

      fd.discount_percent = +value;
      const discount = fd.gross_amount * (fd.discount_percent / 100);
      const post_amount = fd.gross_amount - discount;
      fd.post_amount = +String(post_amount).replace(/,/g, '');
      this.watchTotal = fd.post_amount;

      if (v === '') {
        this.watchDiscountPercent = '0.00';
      }
    },
    watchDiscountAmount(v) {
      const fd = this.formdata_detail;
      const value = v !== '' && typeof v == 'string' ? v.replace(/,/g, '') : 0;
      fd.discount_amount = +value;
      const post_amount = fd.gross_amount - +value;
      fd.post_amount = +String(post_amount).replace(/,/g, '');
      this.watchTotal = fd.post_amount;

      if (v === '') {
        this.watchDiscountAmount = '0.00';
      }
    },
  },
  computed: {
    computedSummaryTotal: {
      get() {
        const value = this.dataSource.reduce((a, b) => a + b.post_amount, 0);
        return value;
      },
    },
    computedSummaryGST: {
      get() {
        const value = this.dataSource.reduce((a, b) => a + b.post_amount, 0);
        const gst = this.dataSource.gst || this.formData.gst_percent;
        const wgst = value * (gst / 100);
        return wgst;
      },
    },
    computedSummaryFinal: {
      get() {
        const value = this.dataSource.reduce((a, b) => a + b.post_amount, 0);
        const gst = this.dataSource.gst || this.formData.gst_percent;
        const wgst = value * (gst / 100);
        return value + wgst;
      },
    },
  },
  data: () => ({
    dataIndex: -1,
    dataColumns: [
      { field: 'price', caption: 'Price', cellTemplate: 'cellPriceTemplate' },
      { field: 'quantity', caption: 'Quantity' },
      {
        field: 'gross_amount',
        caption: 'Gross Amount',
        cellTemplate: 'cellGrossAmountTemplate',
      },
      {
        field: 'discount_percent',
        caption: 'Discount Percent',
        cellTemplate: 'cellDiscountPercentTemplate',
      },
      {
        field: 'discount_amount',
        caption: 'Discount Amount',
        cellTemplate: 'cellDiscountAmountTemplate',
      },
      {
        field: 'post_amount',
        caption: 'Total',
        cellTemplate: 'cellTotalTemplate',
      },
    ],
    addModal: false,
    formdata_detail: {
      id: 0,
      product_id: 0,
      uom: null,
      serial_number: null,
      quantity: 1,
      price: 0,
      post_amount: 0,
      discount_percent: 0,
      discount_amount: 0,
      gross_amount: 0,
    },
    options: {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 2,
        rawValueTrimPrefix: true,
        onValueChanged: (e) => {
          if (+e.target.rawValue) {
            //
          }
        },
      },
      discountPercentCleave: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 2,
        rawValueTrimPrefix: true,
        onValueChanged: (e) => {
          //
        },
      },
    },
    productItems: [],
    watchQty: '1',
    watchPrice: '0.00',
    watchGrossAmount: '0.00',
    watchDiscountPercent: '0.00',
    watchDiscountAmount: '0.00',
    watchTotal: '0.00',
  }),
  mounted() {
    this.loadProducts();
  },
  methods: {
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        product_id: 0,
        uom: null,
        serial_number: null,
        quantity: 1,
        price: 0,
        post_amount: 0,
        discount_percent: 0,
        discount_amount: 0,
        gross_amount: 0,
        status: 'O',
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.formdata_detail = Object.assign({}, defaultForm);

        this.watchQty = '1';
        this.watchPrice = '0.00';
        this.watchGrossAmount = '0.00';
        this.watchDiscountPercent = '0.00';
        this.watchDiscountAmount = '0.00';
        this.watchTotal = '0.00';
      });
    },
    loadProducts() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      productsApi
        .list(params)
        .then(({ data }) => {
          var items = [];
          data.forEach((e) => {
            const item = {
              id: +e.id,
              name: e.name,
            };

            items.push(item);
          });

          this.productItems = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      //
    },
    onReorder(e) {
      const visibleRows = e.component.getVisibleRows();
      const toIndex = this.dataSource.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = this.dataSource.indexOf(e.itemData);
      const newDataSource = [...this.dataSource];

      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);

      this.dataSource = newDataSource;
    },
    editItem(event) {
      if (event) {
        this.addModal = true;
        const { data } = event.row;
        const {
          price,
          quantity,
          discount_percent,
          discount_amount,
          gross_amount,
        } = data;

        const product = this.productItems.find((x) => {
          return x.id === data.product_id;
        });

        data.name = product.name;
        this.dataIndex = event.row.dataIndex;
        this.formdata_detail = Object.assign({}, data);

        this.watchPrice = String(price);
        this.watchQty = String(quantity);
        this.watchGrossAmount = String(gross_amount);

        if (discount_percent) {
          this.watchDiscountPercent = String(discount_percent);
        }

        if (discount_amount) {
          this.watchDiscountAmount = String(discount_amount);
        }
      }
    },
    previewItem(event) {
      //
    },
    moneyFormat(data) {
      return mf(data);
    },
    selectProduct(data) {
      if (data) {
        this.loadProducts();
        const ds = this.dataSource[this.dataIndex];
        const dI = this.dataIndex > -1;
        const item = {
          id: dI ? ds.id : this.dataSource.length + 1,
          product_id: +data.id,
          serial_number: null,
          name: data.name,
          quantity: dI ? String(ds.quantity) : '1',
          price: +data.price,
          discount_percent: dI ? ds.discount_percent : 0,
          discount_amount: dI ? ds.discount_amount : 0,
          gross_amount: dI ? ds.gross_amount : data.price * 1,
          post_amount: dI ? ds.post_amount : data.price * 1,
          status: 'O',
        };

        this.watchPrice = String(data.price);
        this.watchQty = String(item.quantity);
        this.watchDiscountPercent = String(item.discount_percent);
        this.watchDiscountAmount = String(item.discount_amount);
        this.watchGrossAmount = String(item.gross_amount);
        this.watchTotal = String(item.post_amount);

        this.formdata_detail = Object.assign({}, item);
      }
    },
    onSubmit() {
      if (this.dataIndex > -1) {
        this.dataSource.splice(this.dataIndex, 1);
        this.dataSource.splice(this.dataIndex, 0, this.formdata_detail);
      } else {
        this.dataSource.push(this.formdata_detail);
      }

      this.closeModal();
    },
  },
};
</script>

<style></style>
